<template>
	<figure id="logo">
		<img class="img-responsive" alt="Logo" :src="imagem">
	</figure>
</template>

<script>
	import Imagem from "@/assets/images/nova_logo.jpeg"
	export default {
		name: 'LoginEsquerda',
		data() {
			return {
				imagem: Imagem,
			}
		},
	}
</script>

<style lang="scss">
	#logo{
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 125px;
		margin-top: 10px;
		img{
			width: 100%;
		}
	}
</style>